import Logo from "../images/RamerietLogo.svg";

import { useState } from "react";

function Header() {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <>
      <nav className="w-full fixed top-0 left-0 z-50">
        <div className="w-full lg:w-11/12 mx-auto flex justify-between md:justify-start items-center md:items-baseline pr-4 pl-8 md:pl-16 lg:pl-4 my-4 md:mt-6 md:mb-2">
          <a
            href="#hero"
            className="animate-slideDown"
            onClick={() => setToggleMenu(false)}
          >
            <img src={Logo} alt="Logo" className="w-24 md:w-28" />
          </a>
          <ul className="hidden md:flex items-center gap-8 font-rajdhani text-xl ml-8 text-menu_categories animate-fade">
            <li>
              <a href="#inramning" className="hover:text-oliv_green">
                inramning
              </a>
            </li>
            <li>
              <a href="#kunder" className="hover:text-oliv_green">
                kunder
              </a>
            </li>
            <li>
              <a href="#kontakt" className="hover:text-oliv_green">
                kontakt
              </a>
            </li>
            <li>
              <a href="#hitta-hit" className="hover:text-oliv_green">
                hitta hit
              </a>
            </li>
          </ul>
          {/* Buger menu */}
          <div className="md:hidden">
            <div
              className={`cursor-pointer ${
                toggleMenu === true ? "open menu-btn" : "menu-btn"
                }`}
              onClick={() => setToggleMenu(!toggleMenu)}
            >
              <div className="menu-btn__burger"></div>
            </div>
          </div>
          {/* <img
            src={Menu}
            alt="Menu"
            className="md:hidden"
            onClick={() => setToggleMenu(true)}
          /> */}
        </div>
        {/* Lines around the menu */}
        <div className="md:w-100 border-b border-menu_line animate-slide1"></div>
        <div className="w-20 md:w-24 border-b border-menu_line rotate-90 -translate-x-6 md:-translate-x-0 -translate-y-6"></div>
      </nav>

      {/* Mobile menu */}
      <div
        className={`w-screen h-screen fixed left-0 bg-lightText z-20 transform translate-x-full transition duration-300 ease-in-out ${
          toggleMenu
            ? "translate-x-0 opacity-100"
            : "translate-x-full opacity-0"
          }`}
      >
        <div className="flex justify-between p-8 mb-16"></div>
        <ul className="text-center text-menu_categories text-4xl font-rajdhani space-y-8">
          <li className={toggleMenu === true ? "animate-slide" : null}>
            <a href="#inramning" onClick={() => setToggleMenu(false)}>
              inramning
            </a>
          </li>
          <li className={toggleMenu === true ? "animate-slide1" : null}>
            <a href="#kunder" onClick={() => setToggleMenu(false)}>
              kunder
            </a>
          </li>
          <li className={toggleMenu === true ? "animate-slide2" : null}>
            <a href="#kontakt" onClick={() => setToggleMenu(false)}>
              kontakt
            </a>
          </li>
          <li className={toggleMenu === true ? "animate-slide3" : null}>
            <a href="#hitta-hit" onClick={() => setToggleMenu(false)}>
              hitta hit
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Header;
