import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";

const LocationPin = ({ text }) => (
  <div className="flex items-center w-44">
    <Icon icon={locationIcon} className="text-3xl text-pin" />
    <p className="text-lg text-pin font-redHat">{text}</p>
  </div>
);

const Map = ({ location, zoomLevel }) => (
  <div className="map">
    <div className="w-full h-96 md:h-[600px]">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCSHYeNaYY4fC3rEqiXuSxGU7-rufFvffE" }}
        defaultCenter={location.center}
        defaultZoom={zoomLevel}
        options={{ mapId: "e19f3e77567fdc74" }}
      >
        <LocationPin
          lat={location.pin.lat}
          lng={location.pin.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </div>
  </div>
);

export default Map;
