import React from "react";
import background from "../images/Hero-bg.png";
import arrow from "../images/arrow-down.png";

function Hero() {
  return (
    <div
      className="h-screen bg-no-repeat bg-cover bg-bottom_4 md:bg-left lg:bg-top bg-fixed flex flex-col items-center justify-between"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="bg-trans_white md:bg-transparent lg:bg-trans_white h-full md:h-fit pt-32 md:py-8 px-3 md:px-8 md:mt-44 2xl:mt-60 flex flex-col items-center justify-between">
        <div>
          <h1 className="text-big_header font-rufina text-5xl md:text-7xl pb-4 animate-wiggle text-center">
            Experter på inramning
          </h1>
          <ul className="flex justify-center flex-wrap">
            <li className="font-rajdhani uppercase text-3xl text-big_header animate-slide">
              Konstverk
            </li>
            <li className="font-rajdhani uppercase text-xl text-big_header animate-slide1">
              Grafiska tryck
            </li>
            <li className="font-rajdhani uppercase text-5xl text-big_header animate-slide2">
              Fotografier
            </li>
            <li className="font-rajdhani uppercase text-2xl text-big_header animate-slide3">
              Litografier
            </li>
          </ul>
          <div className="md:w-[45rem] md:mx-auto text-xl md:text-2xl text-big_header mt-8 animate-fade text-center px-2 pb-4">
            <p className="font-bold mb-4  font-rajdhani">
              -Växjös go to shop när det gäller inramningar.
            </p>
            <p className="font-light">
              Vi är specialiserade på inramningar av fotografier och konstverk.
              Här tillverkar vi allt för hand, efter dina mått och önskemål. Vi
              erbjuder även många enklare, fina inramningar.
            </p>
          </div>
          <div className="md:w-100 border-b border-menu_line animate-fade"></div>
          <div className="w-20 md:w-24 border-b border-menu_line rotate-90 -translate-x-6 md:-translate-x-0 -translate-y-6 animate-fade"></div>
        </div>
        <a href="#inramning">
          <img
            src={arrow}
            alt="Down arrow"
            className="w-12 mb-2 md:hidden animate-bounce cursor-pointer"
          />
        </a>
      </div>
      <a href="#inramning">
        <img
          src={arrow}
          alt="Down arrow"
          className="w-14 mb-16 hidden md:flex animate-bounce cursor-pointer"
        />
      </a>
      {/*   <div className="lg:flex w-10/12 lg:w-11/12 mx-auto md:pl-6 lg:pl-0">
        <div className="w-full">
          <h1 className="text-big_header font-rufina text-5xl md:text-7xl pb-4 animate-wiggle">
            Experter på <br /> inramning
          </h1>
          <ul className="">
            <li className="font-rajdhani uppercase text-xl text-big_header animate-slide">
              Konstverk
            </li>
            <li className="font-rajdhani uppercase text-xl text-big_header animate-slide1">
              Fotografier
            </li>
            <li className="font-rajdhani uppercase text-xl text-big_header animate-slide2">
              Grafiska tryck
            </li>
            <li className="font-rajdhani uppercase text-xl text-big_header animate-slide3">
              Litografier
            </li>
          </ul>
        </div>
        <div className="w-full h-96 md:flex flex-col lg:items-center justify-center hidden text-sub_text animate-fade">
          <p className="font-bold w-80 mb-4">
            -Växjös go to shop när det gäller inramningar.
          </p>
          <p className="w-80">
            Vi är specialiserade på inramningar av fotografier och konstverk.
            Här tillverkar vi allt för hand, efter dina mått och önskemål. Vi
            erbjuder även många enklare, fina inramningar.
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default Hero;
