import subBg from "../images/subBg.png";
import { useState } from "react";

function Contact() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    const newPost = {
      namn: name,
      telefon: phone,
      email: email,
      meddelande: message,
    };

    fetch("https://formsubmit.co/ajax/info@rameriet.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(newPost),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => console.log(error));

    setStatus("Tack för ditt meddelande, vi återkommer så fort vi kan!");
    function formMessage() {
      setStatus("");
    }
    setTimeout(formMessage, 5000);

    setName("");
    setPhone("");
    setEmail("");
    setMessage("");
  };

  return (
    <div
      className="h-screen bg-no-repeat bg-cover bg-left bg-fixed pt-24"
      style={{ backgroundImage: `url(${subBg})` }}
    >
      <div className="h-full flex flex-col-reverse lg:flex-row container mx-auto">
        {/* Image box */}
        <section className="basis-1/2 flex md:items-center">
          <form
            onSubmit={onSubmit}
            className="w-10/12 mx-auto mb-4 text-sm md:text-base"
          >
            <div className="w-full relative mb-4">
              <label className="font-redHat text-sub_line p-2 absolute">
                namn*:
              </label>
              <input
                type="text"
                name="name"
                required
                onChange={(e) => setName(e.target.value)}
                className="w-full outline-none bg-transparent border-b border-b-menu_line py-2 pl-20 text-sub_text"
                value={name}
              />
            </div>
            <div className="w-full relative mb-4">
              <label className="font-redHat text-sub_line p-2 absolute">
                e-post*:
              </label>
              <input
                type="text"
                name="e-post"
                required
                onChange={(e) => setEmail(e.target.value)}
                className="w-full outline-none bg-transparent border-b border-b-menu_line py-2 pl-20 text-sub_text"
                value={email}
              />
            </div>
            <div className="w-full relative mb-4">
              <label className="font-redHat text-sub_line p-2 absolute">
                telefon:
              </label>
              <input
                type="text"
                name="telefon"
                onChange={(e) => setPhone(e.target.value)}
                className="w-full outline-none bg-transparent border-b border-b-menu_line py-2 pl-20 text-sub_text"
                value={phone}
              />
            </div>
            <label className="flex flex-col font-redHat text-sub_line mb-4">
              meddelande*:
              <textarea
                name="meddelande"
                rows="5"
                cols="50"
                required
                onChange={(e) => setMessage(e.target.value)}
                className="bg-input p-2 outline-none text-sub_text"
                value={message}
              />
            </label>
            <div className="flex justify-end">
              <input
                type="submit"
                value="skicka"
                className="text-white bg-oliv_green uppercase text-center font-redHat w-36 py-2 tracking-wide rounded-sm cursor-pointer hover:bg-big_header"
              />
            </div>
            <p className="text-sub_line font-redHat">{status}</p>
          </form>
        </section>
        {/* Text Box */}
        <section className="basis-1/2 flex md:items-center">
          <div className="w-11/12 mx-auto">
            <div className=" py-2 pl-8 md:pl-20">
              <h3 className="font-dmSerif text-3xl md:text-4xl text-oliv_green">
                Kontakt
              </h3>
              <h6 className="uppercase font-redHat text-sm md:text-base text-oliv_green">
                Rameriet
              </h6>
            </div>
            {/* Lines */}
            <div className="border-b border-menu_line mr-4 md:mr-12"></div>
            <div className="w-20 md:w-24 border-b border-menu_line rotate-90 -translate-x-6 md:-translate-x-0 -translate-y-6"></div>
            {/* information */}
            <address className="px-4 md:px-20 pt-2 lg:py-4 not-italic font-redHat">
              <p className="font-bold text-sub_text text-sm md:text-base py-4">
                Alla beställningar görs på plats hos oss, har ni frågor om pris
                eller bokningstid så går det även bra att ringa eller mejla.
              </p>
              <div className="flex justify-between md:flex-col">
                <div>
                  <p className="text-sub_text text-sm md:text-base pb-4">
                    <a
                      href="https://www.google.com/maps/place/Rameriet/@56.8881499,14.7740958,17z/data=!3m1!4b1!4m5!3m4!1s0x46572582d0dea301:0x559023951ab4077f!8m2!3d56.8881499!4d14.7762845"
                      target="_blank" rel="noopener noreferrer"
                    >
                      Slöjdgatan 2,
                      <br /> 352 46 Växjö
                    </a>
                  </p>
                  <p className="text-sub_text text-sm md:text-base pb-4">
                    <a href="tel:0470-12146">
                      Ring oss på: <br /> 0470-121 46
                    </a>
                  </p>
                </div>
                <div>
                  <p className="text-sub_text text-sm md:text-base pb-4">
                    <a href="mailto:info@rameriet.com">
                      Epost: <br /> info@rameriet.com
                    </a>
                  </p>
                  <p className="text-sub_text text-sm md:text-base pb-4 lg:pb-16">
                    Öppettider: <br /> Måndag – Fredag 8-17 <br /> Lördagar
                    10-14
                  </p>
                </div>
              </div>
            </address>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Contact;
