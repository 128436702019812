import Map from "../components/Map";
import subBg from "../images/subBg.png";

const location = {
  address: "Rameriet",
  center: {
    lat: 56.888381,
    lng: 14.776301,
  },
  pin: {
    lat: 56.888381,
    lng: 14.776301,
  },
};

function Footer() {
  return (
    <div
      className="h-screen bg-no-repeat bg-cover bg-left bg-fixed pt-24 md:pt-32"
      style={{ backgroundImage: `url(${subBg})` }}
    >
      <div className="h-full flex flex-col">
        {/* information */}
        <div className="container mx-auto basis-1/2">
          <address className="px-4 md:px-20 pt-2 lg:py-8 not-italic font-redHat">
            <p className="text-3xl md:text-5xl mb-8 text-oliv_green font-dmSerif">
              Välkommen in till oss!
            </p>
            <div className="lg:flex">
              <div className="basis-1/2 flex items-center justify-between">
                <p className="text-sub_text text-sm md:text-xl pb-4">
                  <a
                    href="https://www.google.com/maps/place/Rameriet/@56.8881499,14.7740958,17z/data=!3m1!4b1!4m5!3m4!1s0x46572582d0dea301:0x559023951ab4077f!8m2!3d56.8881499!4d14.7762845"
                    target="_blank" rel="noopener noreferrer"
                  >
                    Slöjdgatan 2,
                    <br /> 352 46 Växjö
                  </a>
                </p>
                <p className="text-sub_text text-sm md:text-xl pb-4">
                  <a href="tel:0470-12146">
                    Ring oss på: <br /> 0470-121 46
                  </a>
                </p>
                <p className="text-sub_text text-sm md:text-xl pb-4">
                  <a href="mailto:info@rameriet.com">
                    Epost: <br /> info@rameriet.com
                  </a>
                </p>
              </div>
              <div className="basis-1/2 flex items-center justify-center mt-4 md:mt-8 lg:mt-0 mb-4">
                <p className="text-oliv_green text-base md:text-2xl border-l border-l-oliv_green p-4 pl-8">
                  Öppettider: <br /> Måndag – Fredag 8-17 <br /> Lördagar 10-14
                </p>
              </div>
            </div>
          </address>
        </div>
        {/* Map */}
        <div className="basis-1/2">
          <Map location={location} zoomLevel={17} />
        </div>
        <p className="text-center py-4 text-oliv_green">
          © Copyright 2022 | Rameriet AB
        </p>
      </div>
    </div>
  );
}

export default Footer;
