import Hero from "./Hero";
import Service from "./Service";
import Customers from "./Customers";
import Contact from "./Contact";
import Footer from "./Footer";

function Home() {
  return (
    <>
      <section id="hero" className="snap-start h-screen w-screen">
        <Hero />
      </section>
      <section id="inramning" className="snap-start h-screen w-screen">
        <Service />
      </section>
      <section id="kunder" className="snap-start h-screen w-screen">
        <Customers />
      </section>
      <section id="kontakt" className="snap-start h-screen w-screen">
        <Contact />
      </section>
      <section id="hitta-hit" className="snap-start h-screen w-screen">
        <Footer />
      </section>
    </>
  );
}

export default Home;
