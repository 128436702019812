import subBg from "../images/subBg.png";
import tavla from "../images/art.png";

function Customers() {
  return (
    <div
      className="h-screen bg-no-repeat bg-cover bg-left bg-fixed pt-24"
      style={{ backgroundImage: `url(${subBg})` }}
    >
      <div className="h-full flex flex-col-reverse lg:flex-row container mx-auto">
        {/* Text Box */}
        <section className="basis-1/2 flex pt-6 md:pt-0 md:items-center">
          <div className="w-11/12 mx-auto">
            {/* Title */}
            <div className=" py-2 pl-8 md:pl-20">
              <h3 className="font-dmSerif text-3xl md:text-4xl text-oliv_green">
                Kunder
              </h3>
              <h6 className="uppercase font-redHat text-sm md:text-base text-oliv_green">
                Konstnärer & fotografer
              </h6>
            </div>
            {/* Lines */}
            <div className="border-b border-menu_line mr-4 md:mr-12"></div>
            <div className="w-20 md:w-24 border-b border-menu_line rotate-90 -translate-x-6 md:-translate-x-0 -translate-y-6"></div>
            {/* Sub text */}
            <div className="px-4 md:px-20 py-4 lg:py-8">
              <p className="font-redHat font-bold text-sub_text text-sm md:text-base py-4">
                Vi har gjort många inramningar åt fantastiska konstnärer och
                fotografer och här är bara några av dom:
              </p>
              <p className="text-sub_text text-sm md:text-base pb-2">
                Martina Wärenfeldth - Fotograf | Stellan Karlsson - Fotograf |
                Linus Andersson - Fotograf | Mats Aronsson - Konstnär | Daniel K
                Johansson - Fotograf | Anders Bergön - Fotograf
              </p>
            </div>
          </div>
        </section>
        {/* Image box */}
        <section className="basis-1/2 p-4 lg:pb-12">
          {/* <img src={tavla} alt="" className="md:w-9/12 lg:w-11/12 mx-auto" /> */}
          <div
            className="w-full md:w-11/12 mx-auto h-full bg-no-repeat bg-contain bg-center md:mt-8 lg:mt-0"
            style={{ backgroundImage: `url(${tavla})` }}
          ></div>
        </section>
      </div>
    </div>
  );
}

export default Customers;
