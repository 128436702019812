import subBg from "../images/subBg.png";
import tavla from "../images/service.png";

function Service() {
  return (
    <div
      className="h-screen bg-no-repeat bg-cover bg-left bg-fixed pt-24"
      style={{ backgroundImage: `url(${subBg})` }}
    >
      <div className="h-full flex flex-col lg:flex-row container mx-auto">
        {/* Image box */}
        <section className="basis-1/2 p-4 md:py-12">
          <div
            className="w-full md:w-11/12 mx-auto h-full bg-no-repeat bg-contain bg-center"
            style={{ backgroundImage: `url(${tavla})` }}
          ></div>
        </section>
        {/* Text Box */}
        <section className="basis-1/2 flex items-center">
          <div className="w-11/12 mx-auto">
            <div className=" py-2 pl-8 md:pl-20">
              <h3 className="font-dmSerif text-3xl md:text-4xl text-oliv_green">
                Inramning
              </h3>
              <h6 className="uppercase font-redHat text-sm md:text-base text-oliv_green">
                Service
              </h6>
            </div>
            <div className="border-b border-menu_line mr-4 md:mr-12"></div>
            <div className="w-20 md:w-24 border-b border-menu_line rotate-90 -translate-x-6 md:-translate-x-0 -translate-y-6"></div>
            <div className="px-4 md:px-20 py-2 lg:py-8">
              <p className="font-redHat font-bold text-sub_text text-sm md:text-base py-4">
                Vi skapar inramningar med hög kvalité, allt anpassat just för
                dig och dina önskemål.
              </p>
              <p className="text-sub_text text-xs md:text-base pb-2">
                Hos oss finns inga begränsningar på vad du kan rama in, vi kan
                rama in allt från konstverk, fotografier, speglar och även
                föremål, det är bara din fantasi som sätter gränserna.
              </p>
              <p className="text-sub_text text-xs md:text-base pb-8 lg:pb-16">
                Vi har ett stort utbud av ramar i olika material och färger från
                mordent till en mer klassisk stil. Ramen måttanpassas efter dit
                motiv med en passepartout i passande färg som lyfter fram ditt
                konstverk. <br />
                För mer information kontakta oss.
              </p>
              <div className="flex justify-end">
                <a href="#kontakt">
                  <button className="text-white bg-oliv_green uppercase text-center font-redHat w-36 py-2 tracking-wide rounded-sm hover:bg-big_header">
                    Kontakt
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Service;
